import React from "react"
import Consumer from "../layouts/Context"
import 'ssr-intersection-observer'
import { graphql } from "gatsby"
import { TransitionState } from "gatsby-plugin-transition-link"
import { InView } from 'react-intersection-observer'
import ScrollPercentage from 'react-scroll-percentage'
import _ from 'lodash'

// Components
import SEO from "../components/seo"
import ArticleWipe from "../components/ArticleWipe/ArticleWipe"
import ArticleHeader from "../components/ArticleHeader/ArticleHeader"
import ArticleHeaderBtn from "../components/ArticleHeaderBtn/ArticleHeaderBtn"
import ArticleShare from "../components/ArticleShare/ArticleShare"
import ArticleFooter from "../components/ArticleFooter/ArticleFooter"
import ArticleBio from "../components/ArticleBio/ArticleBio"
import ArticleBottom from "../components/ArticleBottom/ArticleBottom"

// Images
import Arrow from "./images/arrow.svg"

class LongFormGallery extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fixed: true,
      currentImage: 0
    }

    this.throttledScrollCheck = _.throttle(this.galleryScrollCheck, 400)

    // Define the bracket (percentage) in which the images will do their changing
    this.scrollBracket = .6

    this.startAndEndPoints = (1 - this.scrollBracket) / 2 + .005
    this.imageCount = this.props.data.markdownRemark.frontmatter.galleryItems.length
    this.scrollStep = this.scrollBracket / this.imageCount
  }

  componentDidMount() {
    this.winWidth = window.innerWidth
  }

  bottomInView = (inView, entry) => {
    this.setState({
      fixed: !inView
    })
  }

  prevSlide = () => {
    if (this.state.currentImage === 0) return

    this.setState({
      currentImage: this.state.currentImage - 1
    })
  }

  nextSlide = () => {
    if (this.state.currentImage === this.imageCount - 1) return

    this.setState({
      currentImage: this.state.currentImage + 1
    })
  }

  galleryScrollCheck = (percentage, inView) => {
    let percRounded = percentage.toPrecision(2)

    if (percRounded < this.startAndEndPoints && this.state.currentImage !== 0) {
      this.setState({
        currentImage: 0
      })

      return
    } else if (percRounded < this.startAndEndPoints) {
      return
    } else if (percRounded > 1 - this.startAndEndPoints && this.state.currentImage !== this.imageCount - 1) {
      this.setState({
        currentImage: this.imageCount - 1
      })
    } else if (percRounded > 1 - this.startAndEndPoints) {
      return
    } else {
      let current = Math.ceil((percRounded - this.startAndEndPoints) / this.scrollStep)

      if (this.state.currentImage === current - 1) return

      this.setState({
        currentImage: current - 1
      })
    }
  }

  render() {
    const post = this.props.data.markdownRemark,
          seoDescription = post.frontmatter.seoDescription,
          seoImage = post.frontmatter.seoImage,
          bgModifier = post.frontmatter.bgModifier,
          title = post.frontmatter.title,
          subtitle = post.frontmatter.subtitle,
          author = post.frontmatter.author,
          authorBarExtra = post.frontmatter.authorBarExtra,
          authorAvatar = post.frontmatter.authorAvatar,
          authorBio = post.frontmatter.authorBio,
          author2 = post.frontmatter.author2,
          authorAvatar2 = post.frontmatter.authorAvatar2,
          authorBio2 = post.frontmatter.authorBio2,
          htmlContent = post.html,
          galleryItems = post.frontmatter.galleryItems

    return (
      <TransitionState>
        {({ transitionStatus }) => {
          return (
            <>
              <SEO
                title={title}
                description={seoDescription ? seoDescription : post.excerpt}
                image={seoImage}
              />

              <Consumer>
                {context =>
                  <ArticleWipe context={context} />
                }
              </Consumer>

              <div className="page-wipe">
                <div className="page-wipe__panel page-wipe__panel--article" ref={pageWipe => (this.pageWipePanel = pageWipe)} />
              </div>

              <div className="article" data-style="narrow" data-bg={bgModifier}>
                <div className="article__inner">
                  <div className="container">

                    <ArticleHeaderBtn pageWipe={this.pageWipePanel} />
                    <ArticleHeader modifier="gallery" title={title} subtitle={subtitle} author={authorBarExtra ? author + authorBarExtra : author} />

                    <div className="cols flex">
                      <ArticleShare modifier="gallery" col={`m12 t1`} />
                      <div className="col m12 t10 d8 dl6">
                        <div className="article__container">
                          <ScrollPercentage
                            className="article__content"
                            dangerouslySetInnerHTML={{ __html: htmlContent }}
                            onChange={this.winWidth >= 1240 ? this.throttledScrollCheck : undefined}
                            threshold={[0]}
                          />
                        </div>
                      </div>
                      <div className="article__gallery-col col m12 d3 dl4">
                        <div className="article__gallery">
                          <div className="article__gallery-images">
                            <ul>
                              {Object.keys(galleryItems).map((index) => {
                                const x = parseInt(index),
                                      url = galleryItems[index].publicURL

                                let desktop = (this.winWidth >= 1240 ? true : false)

                                let active = (this.state.currentImage === x),
                                    before = (this.state.currentImage > x),
                                    opacity = (active || !desktop ? 1 : 0),
                                    translate = (active ? "0%" : (
                                      before ?
                                      (desktop ? "0%" : "-100%" ) :
                                      (desktop ? "0%" : "100%" )
                                    )),
                                    zIndex = (active ? 2 : 1)

                                let styles = {
                                  opacity: opacity,
                                  transform: `translate3d(${(desktop ? 0 : translate)}, ${(desktop ? translate : 0)}, 0)`,
                                  zIndex: zIndex
                                }

                                return (
                                  <li key={index} style={styles} data-active={active}>
                                    <img src={url} width="668" height="849" title={title} alt={title} />
                                  </li>
                                )
                              })}
                            </ul>
                          </div>

                          <div className="article__gallery-bottom">
                            <div className="article__gallery-nav">
                              <div onClick={this.prevSlide}>
                                <img src={Arrow} width="14" height="10" alt="Previous Slide" />
                              </div>
                              <div>
                                {this.state.currentImage + 1}/{this.imageCount}
                              </div>
                              <div onClick={this.nextSlide}>
                                <img src={Arrow} width="14" height="10" alt="Next Slide" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <ArticleFooter fixed={this.state.fixed} />

                  </div>
                </div>

                { authorBio && authorAvatar && (
                  <ArticleBio author={author} authorAvatar={authorAvatar} authorBio={authorBio} />
                )}

                { authorAvatar2 && authorAvatar2 && (
                  <ArticleBio author={author2} authorAvatar={authorAvatar2} authorBio={authorBio2} />
                )}

                <InView
                  onChange={this.bottomInView}
                  threshold={0}
                >
                  {({ inView, ref }) => (
                    <div className="article__bottom" ref={ref} data-anim={inView}>
                      <ArticleBottom pageWipe={this.pageWipePanel} />
                    </div>
                  )}
                </InView>

              </div>
            </>
          )
        }}
      </TransitionState>
    )
  }
}

export default LongFormGallery

export const pageQuery = graphql`
  query LongFormGalleryBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        seoDescription
        seoImage
        chapter
        bgModifier
        title
        subtitle
        author
        authorBarExtra
        authorAvatar {
          publicURL
        }
        authorBio
        author2
        authorAvatar2 {
          publicURL
        }
        authorBio2
        galleryItems {
          publicURL
        }
      }
    }
  }
`